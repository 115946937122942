import { useState } from "react";
import {
 Backdrop,
 Button,
 ContextMenu,
 CoordsInterface,
 Fetcher,
 PageContent,
 Toolbar,
} from "@mchm/common";

const Home = () => {
 const [checklistNames, setChecklistNames] = useState<string[] | null>(null);

 const [contextMenuTriggerCoords, setContextMenuTriggerCoords] =
  useState<CoordsInterface>({ x: 0, y: 0 });
 const [isContextMenuVisible, setIsContextMenuVisible] = useState(false);

 return (
  <>
   <Toolbar
    onEllipsisClick={(event) => {
     setContextMenuTriggerCoords({ x: event.clientX, y: event.clientY });
     setIsContextMenuVisible(true);
    }}
   />
   <ContextMenu
    contextMenuTriggerCoords={contextMenuTriggerCoords}
    menuItems={[{ action: () => {}, name: "Add" }]}
    visible={isContextMenuVisible}
   />
   <Backdrop
    visible={isContextMenuVisible}
    onClick={() => setIsContextMenuVisible(false)}
   />
   <PageContent withNav={true}>
    <h1>Check</h1>
    <Fetcher
     url={`${process.env.REACT_APP_BACKEND_URL}/checklist/checklistNames`}
     onFetched={(result) => {
      setChecklistNames(result);
     }}
     isDataFetchNeeded={checklistNames === null}
    />
    {checklistNames?.map((name) => (
     <Button href={`/${name}`}>{name}</Button>
    ))}
   </PageContent>
  </>
 );
};

export default Home;
