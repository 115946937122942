import { useState } from "react";
import { useParams } from "react-router-dom";

import useChecklist from "../Hooks/useChecklist";

import {
 Backdrop,
 ContextMenu,
 CoordsInterface,
 Fetcher,
 PageContent,
 SelectableCard,
 Toolbar,
} from "@mchm/common";

const Checklist = () => {
 const { checklistName } = useParams();

 const { checklist, saveFetchedChecklist, updateSelectionState, uncheckAll } =
  useChecklist();

 const [contextMenuTriggerCoords, setContextMenuTriggerCoords] =
  useState<CoordsInterface>({ x: 0, y: 0 });

 const [contextMenuVisible, setContextMenuVisible] = useState(false);

 return (
  <>
   <Toolbar
    backPath="/"
    backLabel="Home"
    onEllipsisClick={(event) => {
     setContextMenuTriggerCoords({
      x: event.clientX,
      y: event.clientY,
     });
     setContextMenuVisible(true);
    }}
   />
   <ContextMenu
    contextMenuTriggerCoords={contextMenuTriggerCoords}
    visible={contextMenuVisible}
    menuItems={[
     {
      name: "Uncheck all",
      action: () => {
       setContextMenuVisible(false);
       uncheckAll();
      },
     },
    ]}
   />
   <Backdrop
    onClick={() => {
     setContextMenuVisible(false);
    }}
    visible={contextMenuVisible}
   />
   <PageContent withNav={true}>
    <h1>{checklistName}</h1>
    <Fetcher
     url={`${process.env.REACT_APP_BACKEND_URL}/checklist/${checklistName}`}
     isDataFetchNeeded={checklist === null}
     onFetched={(result) => saveFetchedChecklist(result)}
    />
    <ul className="flex-column">
     {checklist?.items.map((item) => (
      <SelectableCard
       selected={item.checked}
       onClick={() => {
        updateSelectionState(item.name);
       }}
      >
       {item.name}
      </SelectableCard>
     ))}
    </ul>
   </PageContent>
  </>
 );
};

export default Checklist;
