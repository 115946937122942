import Axios from "axios";

import { ChecklistItemInterface } from "../sharedTypes";

export const updateRemoteState = (
 checklistName: string,
 updatedItemArray: ChecklistItemInterface[]
) => {
 Axios.post(
  `${process.env.REACT_APP_BACKEND_URL}/checklist/updateItemState/${checklistName}`,
  updatedItemArray
 );
 // ToDo error handling
};
