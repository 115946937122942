import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { StylingProvider } from "@mchm/common";

import Home from "./Pages/Home";
import Checklist from "./Pages/CheckList";

const App = () => {
 return (
  <StylingProvider regularVisionOn={false}>
   <Router>
    <Routes>
     <Route
      path="/"
      element={<Home />}
     />
     <Route
      path="/:checklistName"
      element={<Checklist />}
     />
    </Routes>
   </Router>
  </StylingProvider>
 );
};

export default App;
