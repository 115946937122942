import { useReducer } from "react";

import checklistReducer from "../Utils/ChecklistReducer";

import { ChecklistInterface } from "../sharedTypes";

const useChecklist = () => {
 const [checklist, dispatchChecklistUpdate] = useReducer(
  checklistReducer,
  null
 );

 const saveFetchedChecklist = (checklist: ChecklistInterface) => {
  dispatchChecklistUpdate({ type: "initialize", checklist: checklist });
 };

 const updateSelectionState = (itemName: string) => {
  dispatchChecklistUpdate({ type: "updateItemState", itemName: itemName });
 };

 const uncheckAll = () => {
  dispatchChecklistUpdate({ type: "uncheckAll" });
 };

 return {
  checklist,
  saveFetchedChecklist,
  updateSelectionState,
  uncheckAll,
 };
};

export default useChecklist;
