import { ChecklistInterface, ChecklistItemInterface } from "../sharedTypes";
import { updateRemoteState } from "./BEHandle";
import { ChecklistReducerActionType } from "./ChecklistReducerTtypes";

const checklistReducer = (
 currState: ChecklistInterface | null,
 action: ChecklistReducerActionType
): ChecklistInterface | null => {
 if (action.type === "initialize") {
  return action.checklist;
 }

 if (action.type === "updateItemState") {
  if (currState !== null) {
   const itemArrayCopy = [...currState.items];
   //ToDo this reduce could probablty reduce by some find function
   const newItemArray = itemArrayCopy.reduce(
    (acc: ChecklistItemInterface[], cur: ChecklistItemInterface) => {
     if (cur.name === action.itemName) {
      cur.checked = !cur.checked;
     }
     acc.push(cur);
     return acc;
    },
    []
   );
   updateRemoteState(currState.name, newItemArray);

   return { ...currState, items: newItemArray };
  }
 }

 if (action.type === "uncheckAll") {
  if (currState !== null) {
   const itemArrayCopy = [...currState.items];
   itemArrayCopy.forEach((item) => (item.checked = false));
   updateRemoteState(currState.name, itemArrayCopy);

   return { ...currState, items: itemArrayCopy };
  }
 }

 return currState;
};

export default checklistReducer;
